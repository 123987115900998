import Articles1 from '../images/article/article_1.webp';
import Articles2 from '../images/article/article_2.jpg';
import Articles3 from '../images/article/article_3.jpg';
import Articles4 from '../images/article/article_4.jpg';
import Articles5 from '../images/article/article_5.png';
import Articles6 from '../images/article/article_6.png';
import Articles7 from '../images/article/article_7.jpg';
import Articles8 from '../images/article/article_8.jpg';
import Articles9 from '../images/article/article_9.jpg';
import Articles10 from '../images/article/article_10.png';
import Articles11 from '../images/article/article_11.jpg';

const articlesData = [
    {
      id: 1,
      title: '3 причины, почему подписчики отписываются — разбор ошибок',
      image: Articles1,
      date1: '28',
      date2: 'Февраля 2024',
      autor: 'Виктория Буханова',
      content: [
        { type: 'paragraph', text: 'Подписчики приходят на страницу, чтобы вдохновляться, учиться или просто развлекаться. Но удерживать их внимание — целое искусство. Когда кнопка «Отписаться» становится их выбором, это уже повод задуматься.' },
        { type: 'paragraph', text: 'Я на основе исследования Sprout Social и BuzzStream разобрала три главных причины, почему люди перестают следить за брендами или блогерами в соцсетях.' },
        { type: 'header', text: '1. Слишком много саморекламы' },
        { type: 'paragraph', text: 'Это лидер среди причин, из-за которых пользователи уходят.' },
        { type: 'paragraph', text: 'По данным исследования, 46% аудитории нажимают «отписаться», если их лента переполнена рекламой.' },
        { type: 'paragraph', text: 'Соцсети — это, прежде всего, площадка для общения. Постоянная самореклама превращает диалог в скучную однотонную презентацию.' },
        { type: 'sub-header', text: 'Совет' },
        { type: 'paragraph', text: 'Используйте правило 80/20. Пусть 80% контента будет информативным, развлекательным и ценным для подписчиков, а рекламу ограничьте до 20%.' },
        { type: 'header', text: '2. Нерелевантный или слишком частый контент' },
        { type: 'paragraph', text: 'Когда публикации начинают повторяться, становятся скучными или слишком частыми, подписчики уходят.' },
        { type: 'list', text: '20% пользователей готовы отказаться от подписки, если контент перестал их цеплять.' },
        { type: 'list', text: 'Еще 15% отметили, что не станут следить за страницей с частотой более шести постов в день.' },
        { type: 'sub-header', text: 'Совет' },
        { type: 'paragraph', text: 'Проанализируйте вовлеченность: что нравится вашей аудитории, какие темы вызывают интерес. Не бойтесь экспериментировать с форматом и частотой публикаций, но держите баланс.' },
        { type: 'header', text: '3. Отсутствие взаимодействия с аудиторией' },
        { type: 'paragraph', text: 'Подписчики задают вопросы, оставляют комментарии, ждут реакции, а в ответ — молчание. Это не просто разочаровывает, а вызывает желание уйти.' },
        { type: 'paragraph', text: 'По данным Sprout Social, 30% пользователей перестанут следить за аккаунтом, если получают только автоматические ответы.' },
        { type: 'sub-header', text: 'Совет' },
        { type: 'paragraph', text: 'Уделяйте внимание каждому комментарию. Показать, что вам важно мнение аудитории — это мощный способ укрепить лояльность.' },

        
    ]
    },
    {
      id: 2,

      title: 'Поколение Z меняет правила игры: что бизнесу нужно знать о новом поколении потребителей',
      image: Articles2,
      date1: '12',
      date2: 'Января 2024',
      autor: 'Алина Иванова',
      content: [
        { type: 'paragraph', text: 'Мы с вами приближаемся к встрече нового 2025 года, а это значит, что уже много ребят, которые родились в 2000-2010 годах вошли во взрослую жизнь и стали частью потребительской массы.' },
        { type: 'paragraph', text: 'Для тех, кто вдруг не слышал: Поколение Z (Gen Z) — термин, применяемый в мире для людей, родившихся, по разным классификациям, примерно с конца 1990-х до начала 2010-х годов. Также представителей поколения Z называют зумерами, хоумлендерами, домоседами, Новым Молчаливым поколением.' },
        { type: 'paragraph', text: 'А еще через десяток лет это поколение станет основой и навсегда изменит подход к поведению потребителя.' },
        { type: 'sub-header', text: 'С чем это связано?' },
        { type: 'paragraph', text: 'Предстоит продавать людям, чье мышление настолько закалено интернетом и цифровизацией, что нам всем только бы успевать за этой скоростью.' },
        { type: 'paragraph', text: 'С чем это связано?' },
        { type: 'list', text: 'Великолепная ориентация в виртуальном мире, зачастую даже лучше, чем в реальном. По данным исследования IBM, 98% поколения Z владеют смартфонами, и 55% проводят в сети более 5 часов в день.' },
        { type: 'list', text: 'Нежелание общаться лично, выходить в оффлайн с незнакомыми людьми для деловых и образовательных встреч при наличии альтернативы в виде переписки или зум-колла. Согласно исследованию Concordia University, 72% представителей поколения Z предпочитают общаться онлайн или в мессенджерах вместо личных встреч или телефонных звонков. 60% респондентов предпочитают видеозвонки или переписку для деловых встреч.' },,
        { type: 'list', text: 'Концентрация у этих ребят сохраняется на определенном объекте намного меньше по времени, чем у более старших. Согласно исследованию Microsoft, средняя продолжительность концентрации внимания у поколения Z составляет всего 8 секунд. Зумеры растут в эпоху клипового мышления, что только способствует сокращению уровня концентрации;' },
        { type: 'list', text: 'Полное отсутствие желания и осознанной надобности к запоминанию любой информации: начиная от формул по физике, заканчивая маршрутом от точки А в точку Б в черте города. Исследование Американской психологической ассоциации показало, что более 70% представителей Gen Z полагаются на поисковые системы для получения фактов и признают, что предпочитают искать информацию онлайн, чем пытаться её запомнить.' },
        { type: 'list', text: 'Хорошая способность к многозадачности, отсюда, кстати, и берется распыление внимания. По данным исследования Sparks & Honey, порядка 64% представителей поколения Z регулярно переключаются между задачами на нескольких устройствах (например, смартфоне, компьютере и телевизоре).' },
        { type: 'list', text: 'Стремление к получению быстрого результата: в обучении, в работе и, конечно, в покупках. В отчете IBM говорится, что 60% представителей поколения Z предпочитают сайты и приложения с мгновенным доступом к информации, и 71% быстро уходят с сайта, если процесс покупки или поиска информации занимает больше времени, чем они ожидали.' },
        { type: 'list', text: 'Любовь к креативу, творческому подходу, создание чего-то нового. Это прослеживается не у всех подряд зумеров, но есть. А еще переживания за судьбу мира в целом. Исследование Adobe Digital Insights показало, что более 56% представителей поколения Z хотели бы заниматься чем-то, что связано с креативом или инновациями. При этом 77% хотят, чтобы работа и деятельность давали им возможность создавать что-то новое.' },
        { type: 'list', text: 'Отсутствие желания планировать жизнь на 3-5-10 лет вперед. Философия: жить “в моменте” и полагаться на случай. По данным исследовательской компании McKinsey, 70% представителей поколения Z предпочитают гибкий подход к жизни, стремясь адаптироваться к изменениям, а не строить долгосрочные планы.' },
        { type: 'sub-header', text: 'Что мы отсюда можем понять?' },
        { type: 'list', text: 'Продавать зумерам эффективнее в интернете и удаленно. Очевидно, но не всегда и не для всех. Иногда бизнес, ориентированный на поколение Z, делает упор на создание крутых молодежных оффлайн-точек продаж, мероприятий и пр. Это всё классно. Но это надо делать после хорошо отлаженного удаленного взаимодействия со своим потребителем.' },
        { type: 'list', text: 'Выстраивать взаимодействие с таким потребителем через систему поддержки по номеру телефона - серьезная ошибка. Они вам не позвонят) Или позвонят, но в очень крайнем случае, если возникнет серьезная проблема. Зумер постарается найти миллион способов как до вас достучаться без телефонного звонка и очень расстроиться, если его усилия не оправдаются. Нам этого не нужно. Поэтому создавайте чат поддержки с быстрым ответом и будет вам счастье.' },
        { type: 'list', text: 'Элементы геймификации в своей системе продаж станут мастхэвом для завоевания молодой аудитории. Сделайте посещение вашей платформы игрой. Везде, где это возможно: системы поддержки, соц. сети, рассылки и пр. Пусть всё это станет частью одной игры, где главный приз для покупателя - выгода и развлечение, а для вас - его лояльность и платежеспособность. Так, бренд Starbucks реализует программу лояльности через приложение, где пользователи за покупки получают «звезды», которые можно обменять на награды, тем самым делая покупки интерактивными. А Nike запустил проект Nikeland в Roblox, виртуальном мире, где пользователи могут поучаствовать в спортивных играх и испытать брендовые продукты.' },
        { type: 'list', text: 'Gen Z ценит честность и подлинность. Избегайте слишком агрессивного маркетинга и сосредоточьтесь на искренней коммуникации. Поддельные отзывы и красивые стоковые фотографии - то, о чем надо забыть раз и навсегда.' },
        { type: 'list', text: 'Эти ребята активно интересуется экологическими, социальными и этическими вопросами. Убедитесь, что ваш проект поддерживает важные ценности для конкретно вашей аудитории. Например, бренд Patagonia активно продвигает инициативы по охране природы и созданию устойчивых продуктов, что резонирует с ценностями поколения Z и помогает укреплять репутацию.' },
        { type: 'list', text: 'Поддерживайте публикации клиентов о вашем продукте, чтобы создать "социальное доказательство" и показать, что ваш бренд – это сообщество. Здесь как раз мы закрываем потребность зумеров в творчестве, участии и самовыражении. Этим ребятам нравится быть причастным к формированию любимого бренда, магазина, проекта, да чего угодно, что им интересно) Например, бренд Adidas привлек к своей кампании Creators Club тысячи молодых пользователей, которые стали амбассадорами компании и создавали уникальный контент на своих страницах, что повысило интерес к бренду.' },
        { type: 'list', text: 'Gen Z ценит быстроту и удобство. Ваша коммуникация и сервис должны быть простыми и интуитивно понятными. Это, на мой взгляд, самый сложный пункт, потому что здесь придется здорово соперничать с конкурентами, особенно крупными игроками рынка. Проблема в том, что когда зумер сделал заказ/оформил заявку в интернете, ему надо, чтобы то, что он хочет, было здесь еще 2 часа назад) Такова реальность. Нужно быть быстрыми, качественными и адаптивными. Подумайте над тем, что вас может выделить в уровне сервиса на фоне конкурентов: возможно вы добавляете в кофе премиальные сиропы, или можете оказать индивидуальную консультацию по стилю каждому посетителю вашего магазина одежды. Нужно найти свою уникальность и продвигать её.' },
        { type: 'list', text: 'Лучший способ взаимодействовать с этим супер-быстрым поколением - это социальные сети. Создавайте воронки именно там, приглашайте инфлюенсеров, разрабатывайте интересности - только так сможете захватить зумеров на их территории.' },
        { type: 'sub-header', text: 'Итак, резюмируем:' },
        { type: 'paragraph', text: 'Для успешного взаимодействия с поколением Z бренды должны быть искренними и подлинными, ставя ценности в основу своей коммуникации. Gen Z ценит честность, социальную ответственность и инновации, поэтому бренды, которые демонстрируют свою приверженность экологии, инклюзивности и этическим принципам, получат их доверие. Основные платформы для общения с Gen Z – это TikTok, ВК, Telegram, Pinterest, YouTube, где особенно эффективен короткий, визуально привлекательный контент.' },
        { type: 'paragraph', text: 'Важно также поддерживать пользовательский контент и предоставлять возможность самим клиентам становиться амбассадорами бренда. Нужно стремиться к быстроте и удобству взаимодействия, чтобы каждый пользовательский опыт казался уникальным. В целом, самое главное во взаимодействии с Gen Z – это выстраивать с ними честные, значимые отношения, которые отражают ценности и образ жизни самого поколения.' },
  
    ]
    },
    {
      id: 3,
      title: 'Миллионы в сезон: как мы помогли вернуть потерянные продажи с помощью WB и Ozon',
      image: Articles3,
      date1: '16',
      date2: 'Ноября 2023',
      autor: 'Максим Горный',
      content: [
        { type: 'paragraph', text: 'Приветствую вас! Меня зовут Максим Горный, я Co-founder и директор отдела интернет-маркетинга digital-агентства полного цикла WESMA. Наша команда занимается разработкой и продвижением сайтов, увеличением трафика, а также оптимизацией продаж на маркетплейсах. Сегодня хочу поделиться историей одного из наших клиентов и рассказать, как мы помогли ему справиться с серьёзными вызовами в бизнесе.' },
        { type: 'paragraph', text: 'Наш клиент — владелец магазина, который специализируется на продаже каркасных и надувных бассейнов, а также товаров для спорта и быта. Ранее магазин успешно работал через собственный сайт, но со временем популярность маркетплейсов начала резко расти. Это привело к усилению конкуренции, а также к снижению трафика и продаж. Несмотря на качественный ассортимент, клиент столкнулся с потерей части рынка.' },
        { type: 'paragraph', text: 'Решение было очевидным — выход на маркетплейсы Ozon и Wildberries. Наша команда взялась за этот проект, помогая настроить витрину, оптимизировать процессы и вернуть позиции на рынке. Цели были амбициозными: восстановить обороты и охватить новую аудиторию, адаптировав бизнес под специфику маркетплейсов.' },
        { type: 'paragraph', text: 'Давайте разберёмся, с какими трудностями мы столкнулись, как преодолевали их, и какие результаты нам удалось достичь.' },
        { type: 'sub-header', text: 'Задачи и вызовы' },
        { type: 'paragraph', text: 'Для успешного старта на маркетплейсах перед нами встали следующие ключевые задачи:' },
        { type: 'paragraph', text: '- Подключение и настройка магазинов на Ozon и Wildberries.' },
        { type: 'paragraph', text: '- Оптимизация ассортимента для платформ и адаптация витрины под требования маркетплейсов.' },
        { type: 'paragraph', text: '- Организация бесперебойной передачи данных о товарах от поставщика.' },
        { type: 'paragraph', text: '- Пересмотр ценообразования для предотвращения продаж в убыток.' },
        { type: 'sub-header', text: 'На пути к реализации этих задач возникли определенные трудности:' },
        { type: 'list', text: 'Ошибки в данных о товарах: Автоматическая передача информации о товарах (изображения, описания) со стороны поставщика работала некорректно. В итоге, изображения не подгружались, а описания отображались с ошибками.' },
        { type: 'list', text: 'Неоптимальное ценообразование: Периодическое повышение закупочных цен поставщика оставалось незамеченным, что привело к ситуации, когда некоторые товары стали продаваться ниже себестоимости.' },
        { type: 'header', text: 'Стратегия и решения' },
        { type: 'sub-header', text: 'Проблема с передачей данных о товарах' },

        { type: 'paragraph', text: 'Автоматическая передача данных о тысячах товаров была удобной, но в данном случае работала некорректно. Не загружались изображения, а описания товаров выглядели не так, как ожидалось. Исправлять вручную было нерентабельно, поэтому мы обратились к поставщику и обозначили проблему. Поставщик доработал фид с участием своих разработчиков, что позволило полноценно представить ассортимент магазина. Однако мы не остановились на этом. Со своей стороны, мы реализовали для клиента специальный хаб, куда загружались данные от поставщика. На основе этого мы разработали индивидуальный функционал в плагинах для маркетплейсов, который учитывал все особенности работы: управление складскими остатками, разделение товаров на крупногабаритные и стандартные, автоматическое применение наценок с учётом коэффициентов.' },
        { type: 'paragraph', text: 'После обработки данные передавались на маркетплейсы в оптимизированном виде, соответствующем их требованиям. Это решение значительно улучшило качество карточек товаров, сделало их более привлекательными для покупателей и упростило процесс выбора, что положительно сказалось на продажах.' },
        { type: 'sub-header', text: 'Оптимизация ценообразования и повышение рентабельности' },
        { type: 'paragraph', text: 'Еще одна трудность заключалась в несвоевременном учете повышения цен на некоторые товары. Это привело к продажам с убытком. Чтобы этого избежать, мы предложили клиенту пересмотреть экономику, включая:' },
        { type: 'paragraph', text: 'Стоимость закупки, Комиссию маркетплейсов, Логистические расходы, Уровень возвратов.' },
        { type: 'paragraph', text: 'Мы проанализировали все факторы, пересчитали наценку и внедрили систему регулярного контроля цен, что позволило восстановить рентабельность товаров и избежать убытков.' },
        { type: 'paragraph', text: 'Важно отметить, что на OZON неплохо продаются сезонные товары вне сезона. Так, например, товары по бассейнам продаются не только летом.' },
  
    ]
    },
    {
      id: 4,
      title: 'Как эффективно продвигать оффлайн бизнес в городе с большой конкуренцией?',
      image: Articles4,
      date1: '04',
      date2: 'Сентября 2023',
      autor: 'Константин Крылов',
      content: [
        { type: 'paragraph', text: 'Привет! На связи Константин Крылов. Я интернет маркетолог и автор телеграм-канала, в котором вы найдете много полезной и важной информации о маркетинге!' },
        { type: 'paragraph', text: 'В этой статье я расскажу как мы в 3 раза увеличили охват и сделали 227 заявок по 256 рублей за 2 месяца для школы танцев в Москве' },
        { type: 'sub-header', text: 'Клиент — танцевальная школа Global Dance' },
        { type: 'paragraph', text: 'Основной запрос — создание стабильного потока заявок в новом сообществе Вконтакте' },
        { type: 'header', text: 'Шаг 1. Аудит и оформление группы' },
        { type: 'paragraph', text: 'В рамках конкретно этого проекта мы не меняли упаковку сообщества, но внесли несколько важных корректировок, а именно:' },
        { type: 'list', text: 'Поменяли стартовое сообщение' },
        { type: 'list', text: 'Изменили название и описание сообщества' },
        { type: 'list', text: 'Переделали карточки услуг' },
        { type: 'list', text: 'Добавили анкету для быстрой записи' },
        { type: 'paragraph', text: 'Благодаря тому, что сообщество стало лучше биться в поисковой выдаче и появились дополнительные призывы к действию, органический охват от поисковых запросов увеличился в три раза!' },
        { type: 'header', text: 'Шаг 2. Запуск рекламы' },
        { type: 'paragraph', text: 'Запуск рекламы был по двум основным направлениям: зумба и бачата.' },
        { type: 'paragraph', text: 'ЦА — женщины от 25 до 50 лет. Также пробовали запускаться на мужчин.' },
        { type: 'paragraph', text: 'Мы искали аудиторию по:' },
        { type: 'list', text: 'гео-точке района Орехово' },
        { type: 'list', text: 'ключевым запросам' },
        { type: 'list', text: 'интересам' },
        { type: 'paragraph', text: 'Тех, кто видел нашу рекламу, мы догоняли другими объявлениями с более выгодным оффером. Трафик вели в сообщения группы и в лид форму.' },
        { type: 'sub-header', text: 'Что сработало лучше всего?' },
        { type: 'paragraph', text: 'Лучше всего зашел формат записи с кнопкой на лид-форму. Связки с видео давали выше конверсию в лид.' },
    ]
    },
    {
      id: 5,
      title: 'Экономика внимания: как подача рекламы меняется в условиях дефицита фокуса',
      image: Articles5,
      date1: '16',
      date2: 'Августа 2023',
      autor: 'Надежда Чифилева',
      content: [
        { type: 'paragraph', text: 'В условиях перенасыщенности информацией внимание становится самым дефицитным ресурсом. Как меняется реклама на разных носителях в ответ на новую реальность?' },
        { type: 'header', text: 'Социальные сети' },
        { type: 'paragraph', text: 'В соцсетях борьба за внимание особенно острая — контент пролистывается за секунды. Поэтому реклама здесь должна быть визуально яркой, лаконичной и цепляющей. Брендам важно захватить внимание пользователя буквально за 2-3 секунды. Видео-контент и короткие клипы получают приоритет, потому что дольше удерживают внимание.' },
        { type: 'header', text: 'Видеоплатформы' },
        { type: 'paragraph', text: 'На YouTube и других платформах пользователи ожидают длинного контента, но и тут важны первые секунды. Поэтому бренды в рекламе часто используют короткие форматы, которые доносят суть за 10–15 секунд. Главная цель — вызвать эмоцию и заинтересовать до того, как пользователь нажмет «пропустить». Видеоблогерам тоже важно на первой минуте сказать что-то такое, что заставит смотреть дальше.' },
        { type: 'header', text: 'Поисковые системы' },
        { type: 'paragraph', text: 'В поисковиках внимание уже нацелено на конкретный запрос. Реклама здесь выигрывает, если точно отвечает на потребность, поэтому важна релевантность ключевых слов. Главное — донести нужную информацию с первых строчек.' },
        { type: 'header', text: 'Оффлайн каналы' },
        { type: 'paragraph', text: 'На наружную рекламу у пользователей буквально секунды: быстрое взаимодействие в транспорте или на улице. Лаконичные месседжи и креативные образы здесь работают лучше всего, особенно если они выделяются среди общего потока визуального шума.' },
        { type: 'header', text: 'Что это значит для бизнеса?' },
        { type: 'paragraph', text: 'Экономика внимания диктует три важных правила для успешной рекламы:' },
        { type: 'list', text: 'Краткость и простота: чем быстрее понятен посыл, тем больше шансов удержать внимание.' },
        { type: 'list', text: 'Эмоции и вовлечение: хорошая реклама должна цеплять, даже если это всего пара секунд.' },
        { type: 'list', text: 'Адаптация под контекст: каждому каналу свой подход. Видеоконтент, креативные посты или наружка — всё должно быть адаптировано к быстрому восприятию.' },
        { type: 'paragraph', text: 'Экономика внимания — это вызов для брендов. Выигрывают те, кто умеет говорить ясно, интересно и кратко.' },
    ]
    },
    {
      id: 6,
      title: 'Что делать, если ваше агентство в шаге от развала? Исправляем ошибки в менеджменте',
      image: Articles6,
      date1: '11',
      date2: 'Июля 2023',
      autor: 'Надежда Чифилева',
      content: [
        { type: 'paragraph', text: 'Что делать, если производительность сотрудников начинает падать? Как оптимизировать планерки и избежать потери задач? Рассказываем, как мы нашли ошибки в менеджменте и что помогло нам не развалить команду. Читайте, чтобы не повторить наших ошибок или узнать, как выйти из такого положения в 4 шага.' },
        { type: 'paragraph', text: 'Делегирование — это когда ты уверен в результате выполнения задачи, что даже не будешь перепроверять результат.' },
        { type: 'paragraph', text: 'Столкнулись с проблемой в команде —производительность всех сотрудников упала. И, вроде, все понимаем какие задачи, но никто не понимает, что и кто делает.' },
        { type: 'header', text: 'Пролог. Этап 0.' },
        { type: 'paragraph', text: 'Разнесли все задачи четко по специалистам и разделили зоны ответственности.' },
        { type: 'paragraph', text: 'В какой-то момент стало понятно, что весь менеджмент проектов посыпется в ближайшее время. Эту проблему подсветила маркетолог. Тогда стало понятно, что ключевой проблемой становится переработка человеко-часов из-за ожидания выполнения задач "кем-то" из "какого-то" количества человек.' },
        { type: 'header', text: '1. Регламенты для всех сотрудников' },
        { type: 'paragraph', text: 'Мы начали писать регламенты. Маркетологу, аккаунт-менеджеру, таргетологам, директологу, помощникам, и... руководителю. Внедряем и оптимизируем в режиме реального времени.' },
        { type: 'header', text: '2. Итоги после каждой планерки' },
        { type: 'paragraph', text: 'Бывало так, что задачи между сотрудниками могли теряться из-за рассинхронизации во время звонков. Мы обновили формат планерок и стали сверяться по задачам в конце каждого спича по проектам с ответственным сотрудником.' },
        { type: 'paragraph', text: 'Теперь специалист расставляет свои задачи самостоятельно, а аккаунт-менеджер контролирует выполнение и вносит дополнительные задачи, поступающие от заказчиков.' },
        { type: 'paragraph', text: 'Этот пункт внедрили 3 недели назад и уже чувствуется результат. Производительность выросла.' },
        { type: 'header', text: '3. Переход к одному сервису по управлению задачами' },
        { type: 'paragraph', text: 'Сначала было сложно, но через 1-2 недели, чуть-чуть проконтролировав, мы перенесли туда всю работу.' },
        { type: 'sub-header', text: 'Плюсы очевидны:' },
        { type: 'list', text: 'Каждый сотрудник ставит задачу сам себе и принимает ответственность за её выполнение;' },
        { type: 'list', text: 'Вся коммуникация в одном месте (все-таки задачником мы и раньше пользовались, но не всегда)' },
        { type: 'list', text: 'Ничего не теряется по чатам' },
        { type: 'list', text: 'Общая производительность растет' },
        { type: 'paragraph', text: 'Конечно, это требует большего вовлечения со стороны каждого специалиста и может занимать на 10-15% больше времени в начале, но затем становится наоборот, время экономится.' },
        { type: 'header', text: '4. Регламент работы с заказчиками' },
        { type: 'paragraph', text: 'Начали прорабатывать регламент работы с заказчиками. Этот процесс был налажен с самого начала, но когда в команде произошли перестановки мы не адаптировали нового сотрудника под наши регламенты работы, из-за чего, периодически, случались дисконнекты в задачах.' },
        { type: 'paragraph', text: 'Очень важный пункт, потому что именно в тот момент мы проходили фазу роста и брали по ~20 проектов в портфель на 2 специалистов и 1-го аккаунт-менеджера.' },
        { type: 'header', text: 'Как мы стабилизировались и к чему пришли' },
        { type: 'paragraph', text: 'Регулярные "чеки" в конце планерок вытаскивают 10-15% задач. Выполнение задач через задачник позволяет заниматься планированием. Собрали со всех заказчиков обратную связь по работе и по дальнейшим планам.' },
        { type: 'paragraph', text: 'Стало понятно, кто и что будет делать хотя бы на дню и неделю. Стало понятно, какие проекты останутся, а какие нет.' },
    ]
    },
    {
      id: 7,
      title: 'Борьба с конкурентами: не война, а стратегия',
      image: Articles7,
      date1: '18',
      date2: 'Марта 2023',
      autor: 'Элина Малагова',
      content: [
        { type: 'paragraph', text: 'Конкуренция — это естественная часть бизнеса. Важно не просто конкурировать, а делать это эффективно, выделяясь на фоне других и завоевывая лояльность клиентов.' },
        { type: 'header', text: '1. Позиционирование и дифференциация:' },
        { type: 'paragraph', text: 'Определите свою нишу: найдите свою уникальную позицию на рынке, что вы делаете лучше, чем конкуренты.' },
        { type: 'paragraph', text: 'Создайте уникальное торговое предложение (УТП): выделите себя, предложите что-то особенное, что отличает вас от конкурентов.' },
        { type: 'paragraph', text: 'Постройте сильный бренд: создайте узнаваемый образ, используйте яркий логотип, стильный дизайн, необычную презентацию.' },
        { type: 'header', text: '2. Качество и ценность:' },
        { type: 'paragraph', text: 'Повышайте качество товаров/услуг: предлагайте продукцию или сервис выше среднего, стремитесь к совершенству.' },
        { type: 'paragraph', text: 'Создавайте ценность для клиентов: изучайте потребности вашей целевой аудитории, решайте их проблемы и предоставьте решения, которые превышают ожидания.' },
        { type: 'paragraph', text: 'Превосходный клиентский опыт: создайте положительное впечатление у каждого клиента: отзывчивая поддержка, быстрая доставка, индивидуальный подход.' },
        { type: 'header', text: '3. Маркетинг и продвижение:' },
        { type: 'paragraph', text: 'Используйте разнообразные каналы маркетинга: сайт, социальные сети, email-маркетинг, реклама и т.д.' },
        { type: 'paragraph', text: 'Создайте интересный контент: делитесь полезной информацией, увлекательными историями, инсайтами от экспертов.' },
        { type: 'paragraph', text: 'Повышайте осведомленность о бренде: участвуйте в мероприятиях, создавайте партнерства, работайте с инфлюенсерами. ' },
        { type: 'header', text: '4. Анализ и оптимизация:' },
        { type: 'paragraph', text: 'Изучайте конкурентов: следите за их действиями, анализируйте их сильные и слабые стороны.' },
        { type: 'paragraph', text: 'Проводите A/B-тестирование: проверяйте разные варианты маркетинговых кампаний, дизайна, контента и т.д.' },
        { type: 'paragraph', text: 'Вносите коррективы: постоянно анализируйте результаты и оптимизируйте свою стратегию в соответствии с изменениями на рынке.' },
        { type: 'header', text: '5. Создание конкурентного преимущества:' },
        { type: 'paragraph', text: 'Инновации: создайте новую услугу, товар или технологию, которой нет у конкурентов.' },
        { type: 'paragraph', text: 'Специализация: станьте экспертом в узкой нише, чтобы привлечь целевую аудиторию, которую не могут обслужить другие компании.' },
        { type: 'paragraph', text: 'Создание партнерств: объединитесь с компаниями в смежных нишах и предложите комплексные решения.' },
        { type: 'sub-header', text: 'Подведем итоги:' },
        { type: 'list', text: 'Следите за тенденциями: будьте в курсе новых технологий, продуктов, форматов контента.' },
        { type: 'list', text: 'Изучайте отзывы клиентов: понимайте, что работает хорошо, а что нужно изменить.' },
        { type: 'list', text: 'Учитесь на ошибках: анализируйте неудачные кампании, чтобы избегать их в будущем' },
        { type: 'paragraph', text: 'Конкуренция — это не война, а гонка за лучшее решение для клиента. Будьте уверены в своих силах, стремитесь к совершенству и создайте уникальное предложение, которое привлечет и удержит клиентов!' },

    ]
    },
    {
      id: 8,
      title: 'Мода и деньги: инвестиции в fashion-ритейл: риски и возможности',
      image: Articles8,
      date1: '26',
      date2: 'Февраля 2023',
      autor: 'Надежда Чифилева',
      content: [
        { type: 'paragraph', text: 'Как начать свой бизнес в сфере моды и одежды, с какими трудностями столкнуться и какие возможности не упустить.' },
        { type: 'header', text: 'Риски в fashion-ритейле:' },
        { type: 'list', text: 'Высокая конкуренция. Fashion-ритейл – это рынок с множеством игроков, от крупных сетевых магазинов до микро-брендов. Чтобы выделиться, нужно предлагать уникальный ассортимент, качественную продукцию и отличную сервис.' },
        { type: 'list', text: 'Изменчивость моды. Тренды в моде меняются очень быстро, и то, что было актуально вчера, может уже не пользоваться спросом завтра. Поэтому важно уметь быстро реагировать на изменения и обновлять ассортимент.' },
        { type: 'list', text: 'Сезонность. В одежной индустрии есть ярко выраженные сезоны. В высокий сезон продажи растут, но в низкий могут резко падать. Чтобы сгладить сезонку, важно заранее закупать товары, предлагать специальные акции и проводить рекламные кампании.' },
        { type: 'list', text: 'Сложности с логистикой. Доставка одежды и обуви имеет свои особенности: необходимость соблюдать определенный температурный режим, упаковку и транспортировку хрупких товаров. Эти нюансы следует учитывать при планировании логистических процессов.' },
        { type: 'list', text: 'Сложности с поставщиками. Найти надежных поставщиков качественной одежды и обуви – непростая задача. Необходимо тщательно проверять поставщиков, оценивать их репутацию и качество продукции.' },
        { type: 'list', text: 'Необходимость в инвестициях. Fashion-ритейл – это инвестиционно-емкий бизнес. Для открытия магазина или онлайн-платформы потребуются значительные вложения на аренду, оснащение, закупку товара, маркетинг и рекламу.' },
        { type: 'header', text: 'Возможности в fashion-ритейле:' },
        { type: 'list', text: 'Востребованность. Сфера моды и одежды всегда будет востребована, поскольку люди хотят выглядеть стильно и комфортно.' },
        { type: 'list', text: 'Разнообразие ниши. Существует много разных сегментов в fashion-ритейле, от массового рынка до премиум-сегмента. Это позволяет выбрать направление деятельности, которое вам близко и интересно.' },
        { type: 'list', text: 'Онлайн-торговля. Интернет-магазины одежды и обуви набирают популярность, и это отличный способ начать бизнес с минимальными инвестициями.' },
        { type: 'list', text: 'Сотрудничество с брендами. Можно стать официальным дистрибьютором известных брендов или проводить совместные промо-акции.' },
        { type: 'list', text: 'Создание собственного бренда. Разработка и продвижение собственного бренда одежды – задача не из простых, но она дает возможность реализовать свои творческие идеи и получить настоящее удовлетворение от дела.' },
        { type: 'list', text: 'Развитие социальных сетей. Активное продвижение в Instagram, TikTok и других социальных сетях – неотъемлемая часть успеха в fashion-ритейле.' },
        { type: 'list', text: 'Маркетплейсы. Продажа одежды через маркетплейсы (Wildberries, Ozon) – отличный способ расширить аудиторию и получить доступ к большому числу покупателей.' },
        { type: 'header', text: 'Как минимизировать риски и увеличить шансы на успех:' },
        { type: 'list', text: 'Тщательно проведите анализ рынка. Изучите конкурентов, их цены, ассортимент и маркетинговые стратегии.' },
        { type: 'list', text: 'Разработайте четкую бизнес-модель. Определите целевую аудиторию, сформулируйте уникальное торговое предложение, продумайте стратегию маркетинга и продаж.' },
        { type: 'list', text: 'Сделайте акцент на качестве. В fashion-ритейле качество товаров играет ключевую роль. Покупатели готовы платить за качественную одежду, которая долго прослужит и будет отлично сидеть.' },
        { type: 'list', text: 'Создайте сильный бренд. Разработайте уникальный стиль, логотип, фирменный цвет. Помните, что бренд – это не только название, но и определенный образ жизни, ценности и отношение к клиентам.' },
        { type: 'list', text: 'Позаботьтесь о логистике. Выберите надежных поставщиков, продумайте систему доставки и хранения товаров.' },
        { type: 'list', text: 'Используйте маркетинговые инструменты. Реклама в социальных сетях, контекстная реклама, email-маркетинг, SМS-рассылка – все это поможет вам привлечь новых клиентов и увеличить продажи.' },
        { type: 'sub-header', text: 'Заключение:' },
        { type: 'paragraph', text: 'Инвестиции в fashion-ритейл могут принести хорошую прибыль, но это не простой бизнес. Чтобы успеть, нужно быть в курсе трендов, уметь анализировать рынок, предлагать качественный товар и строить долгосрочные отношения с клиентами. Однако если вы готовы вложить свои силы и время, fashion-ритейл может стать для вас настоящим источником дохода и реализации творческого потенциала.' },
    ]
    },
    {
      id: 9,
      title: '5 лайфхаков для монетизации — 1 платформа',
      image: Articles9,
      date1: '20',
      date2: 'Ноября 2022',
      autor: 'Максим Горный',
      content: [
        { type: 'paragraph', text: 'Иногда блоггинг чувствуется как бег в колесе и автор, конечно, белка. Что мы можем сделать, чтобы облегчить жизнь и понизить градус многозадачности как-то подумали айтишники и создали ClickContent.' },
        { type: 'header', text: 'Быстрая сделка' },
        { type: 'paragraph', text: 'Сложные договоры, подписание актов и прочие бюрократические моменты можно оставить в прошлом. Вы продавец и у вас есть покупатель? Все, что нужно — это загрузить контент, любой файл, документ и создать ссылку на оплату. Высылайте ссылку покупателю — получайте оплату и выводите деньги на привязанную карту. Кстати, для оплаты не нужно регистрироваться на платформе, а покупка придет на почту или в мессенджер.' },
        { type: 'header', text: 'Автоматизация продаж' },
        { type: 'paragraph', text: 'У вас массовый продукт? Не нужно настраивать чат-бота, не нужно отвечать в директе, нужно просто «повесить» ссылку на видном месте в блоге или на сайте. Опишите продукт и его необходимость, поставьте продающий текст рядом, добавьте призыв к действию, а дальше все будет работать само.' },
        { type: 'header', text: 'Донаты' },
        { type: 'paragraph', text: 'Донейшн порадует всегда. Это благодарность от подписчиков, за то, что вы существуете, поддержка, если вам нужна помощь, и мотивация творить дальше. Для благотворителя становится важным причастность к тому, что вы делаете. Это взаимовыгодный обмен: благотворитель благодарит вас рублем, чтобы и дальше наблюдать за вашей деятельностью в интернете. Донаты часто собирают во время стримов или прямых эфиров и блогер отвечает на вопросы от тех, кто задонатил, в первую очередь, а если блогер популярный, вопросов поступает масса, а время ограничено — пожалуй, это единственный способ гарантировать внимание к конкретному сообщение' },
        { type: 'header', text: 'Сбор на цели' },
        { type: 'paragraph', text: 'Иногда просто так не достаточно, но подписчики будут рады помочь и поучаствовать в каком-то глобальном замысле. Этот пункт как краудфандинг, может у вас новый виток в развитии и нужно финансирование, может быть пора обновить технику, но сейчас не лучшие времена, а может у вас есть бизнес-проект, MVP и вы хотите найти инвестора. Для подобных вариантов подойдет функционал сбор на цели.' },
        { type: 'header', text: 'Помощь в создании инфопродукта' },
        { type: 'paragraph', text: 'В ClickContent работает сильная маркетинговая команда и отдел дизайнеров. И они готовы делиться своим опытом с креаторами. Если вы никогда не создавали цифровой продукт, но очень хотите, можно обратиться за помощью. Они помогут создать гид, чек-лист, курс или что-то еще по конкретной тематике, в любом случае, это будет качественный инфопродукт, который можно начать продавать через ClickContent мгновенно. Условия простые — очень этого хотеть и иметь аудиторию от 1000 подписчиков. На сайте есть почта, куда можно отправить заявку.' },
    ]
    },
    {
      id: 10,
      title: 'Сайт, которому доверяют: Как визуал и контент увеличивают лояльность и продажи',
      image: Articles10,
      date1: '03',
      date2: 'Октабря 2022',
      autor: 'Максим Горный',
      content: [
        { type: 'paragraph', text: 'Создать сайт, который работает на твой бренд — не значит просто выбрать яркие цвета и классный шрифт. Тут, как и в хорошей рекламе, нужно продумать каждый штрих, каждую строчку. Брендовые сайты типа Apple, Nike, Tesla создают с нуля, продумывая не только эстетику, но и удобство, логичность контента и главное — доверие. Расскажу, как и твой сайт может стать визитной карточкой, которая не просто выглядит красиво, а помогает продавать.' },
        { type: 'header', text: '1. Визуал — не только красиво, но и продуманно' },
        { type: 'paragraph', text: 'Секрет брендов с сильным визуалом в том, что они продумывают всё — от цветовой палитры до логотипа и выравнивания блоков. По данным исследования ColorCom, до 90% первых впечатлений о бренде формируются именно на основе цветов. Например, синий цвет часто ассоциируется с надёжностью и спокойствием, поэтому его используют такие гиганты, как Facebook и LinkedIn.' },
        { type: 'paragraph', text: 'Что нужно тебе: Выбери цвета, которые транслируют ценности твоего бренда. Если ты — бренд, связанный с фитнесом или динамикой, сделай акцент на энергичных оттенках (оранжевый, красный), а для товаров класса люкс подойдут более сдержанные, тёмные тона. Но главное — не переборщи: если цвета будут раздражать глаз, люди уйдут через пару секунд.' },
        { type: 'header', text: '2. Лицо компании: дизайн под аудиторию' },
        { type: 'paragraph', text: 'Понимание своей аудитории — твой главный компас. Вспомни сайт Airbnb: с первых секунд тебе дают понятный интерфейс и симпатичный визуал. Компания ставит акцент на уюте, комфорте и лёгкости. И тут важно не только, как это выглядит, но и как воспринимается твоей целевой аудиторией.' },
        { type: 'paragraph', text: 'Что нужно тебе: Подумай, кто твоя аудитория. Молодёжная одежда? Сделай акцент на креативе и динамичности. Бизнес-услуги? Больше структурированности, лаконичности. Чем более конкретно ты определишь, что для них важно, тем легче сайт поможет строить доверие.' },
        { type: 'header', text: '3. Контент — основа доверия' },
        { type: 'paragraph', text: 'Глупо считать, что на сайте важен только визуал, если контент сам по себе ничем не цепляет. Исследование Nielsen Norman Group показывает, что до 79% пользователей пробегают по сайту, только сканируя текст. Поэтому текст должен быть лаконичным, информативным, с выгодой для пользователя.' },
        { type: 'paragraph', text: 'Что нужно тебе: Придумай УТП — уникальное торговое предложение — и проговаривай его на каждом важном экране сайта. Используй лёгкий, понятный язык, избегай слишком сложных формулировок и «воды». Ключевые вопросы посетителя: «Как это решит мою проблему?» и «Почему я должен доверять именно вам?» Ответь на них так, чтобы они закрыли все сомнения за несколько секунд.' },
        { type: 'header', text: '4. Триггер на доверие: отзывы и кейсы' },
        { type: 'paragraph', text: 'Пользователь хочет видеть, как твой продукт решает задачи других. Отзывы и кейсы работают сильнее, чем ты думаешь. Тот же Amazon добавляет разделы с отзывами и рейтингами прямо под товаром, потому что данные показывают, что это увеличивает конверсии на 10-15%.' },
        { type: 'paragraph', text: 'Что нужно тебе: Покажи не просто сухие отзывы. Если есть успешные кейсы, то прямо в видеоформате. Объясни, какую проблему закрыл твой продукт, почему клиент остался доволен и как это можно повторить. Графики, числа, реальные примеры — всё это вызывает больше доверия, чем стандартные «клиенты довольны».' },
        { type: 'header', text: '5. CTA — подводим пользователя к действию' },
        { type: 'paragraph', text: 'Как бы ни был красив и удобен сайт, без ясного призыва к действию (CTA) конверсии не будет. CTA — это кнопка или сообщение, которое подводит человека к покупке, оставлению заявки, скачиванию. Исследования показывают, что до 47% всех CTA не привлекают внимания, так как либо плохо видны, либо написаны «в стол».' },
        { type: 'paragraph', text: 'Что нужно тебе: Сделай CTA заметным и ясным. Пиши конкретно, что человек получит: не просто «Узнать больше», а «Получить бесплатную консультацию» или «Начать зарабатывать с сайта уже сегодня». Направляй посетителя с каждым кликом. Если твой сайт не подталкивает пользователя к действию — значит, он зря тратит твоё время.' },
        { type: 'header', text: 'Итог: создаем сайт, который продаёт через доверие' },
        { type: 'paragraph', text: 'Всё сводится к балансу: эстетика, удобство и доверие. Только грамотно продуманный сайт сможет вызвать то первое положительное впечатление, которое переведёт пользователя в разряд постоянных клиентов. Не забывай, что сайт — это лицо бренда, визитка, которая работает 24/7, и от того, насколько он продуман, зависит, будут ли к тебе возвращаться.' },
    ]
    },
    {
      id: 11,
      title: 'Как понравиться вашему клиенту. Инструкция к применению',
      image: Articles11,
      date1: '12',
      date2: 'Июль 2022',
      autor: 'Алексей Черныш',
      content: [
        { type: 'list', text: 'Говорите на одном языке. Используйте профессиональные термины и акцентируйте внимание на отраслевых знаниях. Это не только покажет, что вы понимаете их бизнес, но и создаст ощущение, что вы "из их среды".' },
        { type: 'list', text: 'Заботитесь о результатах клиента. Важно показывать, что ваш приоритет — это результаты, которые клиент хочет достичь. Ваша заинтересованность в их успехе создает атмосферу партнерства.' },
        { type: 'list', text: 'Проявляйте профессионализм и стабильность. Клиенты ценят надежность и способность следовать намеченным планам и срокам. Постоянная готовность к конструктивному диалогу и выполнению обязательств вызывает доверие и уважение.' },
        { type: 'list', text: 'Будьте внимательны к деталям и инициативны. Предлагайте идеи, которые могли бы принести клиенту выгоду, и делитесь актуальными данными. Это создаст впечатление не просто профессионала, но и эксперта, который глубоко вовлечен в успех клиента.' },
        { type: 'header', text: 'Будьте похожим на клиента' },
        { type: 'list', text: 'Общими ценностями и целями. Узнайте, что для клиента важно в бизнесе — например, надежность, новаторство или стремление к качеству. Акцентируйте, что в вашей работе и подходе эти ценности также важны, что покажет общность взглядов.' },
        { type: 'list', text: 'Похожим подходом к делу. Если клиент ориентирован на четкость и структурированность, поддерживайте такой стиль общения и отчетности. Если клиент предпочитает гибкость и нестандартные решения, проявляйте творчество и находчивость.' },
        { type: 'list', text: 'Пониманием его отрасли. Даже поверхностные знания о специфике его рынка помогут вам говорить на одном языке. Исследуйте и упоминайте конкретные вызовы и тенденции, которые актуальны для его отрасли, это демонстрирует вашу вовлеченность и знание контекста.' },
        { type: 'list', text: 'Схожим пониманием успеха. В зависимости от того, как клиент воспринимает успех (лидерство на рынке, стабильный рост, инновации), выражайте согласие с этими приоритетами и покажите, что ваш подход направлен на достижение подобных целей.' },
        { type: 'list', text: 'Внешними атрибутами. Одежда, соответствующая стилю (Корпоративный, бизнес, неформальный стиль) Цветовая гамма, аккуратность и ухоженность. Жесты и мимика, манера речи, стиль работы' },
        { type: 'header', text: 'Какой комплимент можно сделать клиенту?' },
        { type: 'list', text: '«Вы действительно знаете свой рынок». Это подчеркнет его экспертность и профессионализм.' },
        { type: 'list', text: '«Ваш подход к бизнесу вдохновляет». Это универсальный комплимент, который можно подкрепить конкретикой, например: «То, как вы ставите приоритеты в устойчивом росте, — это пример для многих компаний».' },
        { type: 'list', text: '«Ваши стандарты качества заметны даже в мелочах». Это отличный способ показать, что вы цените их внимание к деталям и высокий уровень работы.' },
        { type: 'list', text: '«Вы всегда находите лучшие решения». Этот комплимент подойдет клиентам, которые стремятся к оптимизации и инновациям.' },
        { type: 'list', text: '«Работать с вами — это удовольствие, вы так четко формулируете свои цели и задачи». Это создаст впечатление, что клиент упрощает вашу работу и вы цените его профессионализм.' },
        { type: 'list', text: '«Вы создаете команду, которая вдохновляет». Этот комплимент особенно актуален, если клиент действительно выстраивает сплоченную команду, с которой легко работать.' },
        { type: 'paragraph', text: 'Быть похожим на клиента — значит понимать его ценности, подход и стиль работы. Поддерживая это взаимопонимание и искренне интересуясь его достижениями, вы создаете атмосферу доверия и сотрудничества. Позитивное и профессиональное отношение, вместе с похвалой за конкретные заслуги клиента, всегда работает в вашу пользу.' },
    ]
    },
    

];
  
export default articlesData;