import React from 'react';
import Wda_sprite from '../images/wda-sprite.svg';



const Regulations = () => {
  return (
    <section className="section page__wrapper">
    <h2 className="section__title">
    <svg className="section__title-icon svg-icon" aria-hidden="true">
        <use xlinkHref={`${Wda_sprite}#title-pixel-1`}></use>
      </svg>
      1. Общие положения:
	</h2>
  <p className="page-about__text mb-20">1.1. International Marketing Championship проводится ежегодно в период с 15 по 30 сентября, с целью стимулирования развития маркетинговой индустрии через признание лучших специалистов и их достижений.</p>
  <p className="page-about__text mb-20">1.2. Организатором конкурса выступает <b>Российская Ассоциация Маркетинговых Услуг (РАМУ)</b> — ведущая организация, поддерживающая маркетинговые инициативы на национальном и международном уровнях.</p>
  <p className="page-about__text mb-20">1.3. Основная цель конкурса — выявление и поощрение лучших специалистов в области маркетинга и рекламы, чьи достижения внесли значительный вклад в развитие индустрии, повлияли на рыночные тренды и представляют собой примеры инновационного подхода.</p>
  <p className="page-about__text mb-20">1.4. Конкурс включает пять ключевых номинаций, каждая из которых подчеркивает важные аспекты маркетинговой деятельности. Оценка проектов проводится на основе их креативности, эффективности и способности влиять на индустрию.</p>
  <p className="page-about__text mb-20">1.5. Итоги конкурса подводятся 1 октября на торжественной церемонии награждения, на которой объявляются победители, награждаются дипломами и памятными призами.</p>
  <hr />
  <h2 className="section__title marg-40">
    <svg className="section__title-icon svg-icon" aria-hidden="true">
        <use xlinkHref={`${Wda_sprite}#title-pixel-2`}></use>
      </svg>
      2. Порядок участия:</h2>
      <p className="page-about__text mb-20">2.1. Заявки на участие принимаются с 20 августа по 5 сентября текущего года. Участники обязаны предоставить полные и достоверные сведения о своих достижениях в маркетинге, подкрепленные конкретными результатами.</p>
      <p className="page-about__text mb-20">2.2. Участниками конкурса могут быть специалисты в области маркетинга и рекламы с опытом работы не менее трех лет, что подтверждает их профессиональные навыки и вклад в индустрию.</p>
      <p className="page-about__text mb-20">2.3. Для участия необходимо заполнить заявку на официальном сайте конкурса и прикрепить материалы, подтверждающие достижения участника (кейсы, отчеты, данные по эффективности).</p>
      <p className="page-about__text mb-20">2.4. Каждый участник может подать заявку в одну или несколько номинаций, если его достижения соответствуют требованиям номинаций.</p>
      <p className="page-about__text mb-20">2.5. Организаторы оставляют за собой право запросить дополнительную информацию или материалы для более детальной оценки, если представленные данные требуют уточнений.</p>
      <hr />
      <h2 className="section__title marg-40">
    <svg className="section__title-icon svg-icon" aria-hidden="true">
        <use xlinkHref={`${Wda_sprite}#title-pixel-3`}></use>
      </svg>
      3. Номинации:</h2>
      <p className="page-about__text mb-20">3.1. <b> Лучший маркетинговый проект года</b> — награждаются проекты, которые значительно повлияли на развитие бренда или компании, показали высокую эффективность и креативность. Ключевые критерии: креативность, стратегия, инновации и результативность (ROI).</p>
      <p className="page-about__text mb-20">3.2. <b>Инновации в маркетинге</b> — оцениваются проекты, внедрившие новейшие технологии или методы, такие как искусственный интеллект, машинное обучение или виртуальная реальность в маркетинговую стратегию. Важен уровень инновационности и влияние на рынок.</p>
      <p className="page-about__text mb-20">3.3. <b>Лидер цифрового маркетинга</b> — признаются специалисты или команды, работающие с цифровыми каналами, такими как SEO, SMM, контекстная реклама и email-маркетинг, демонстрирующие исключительные результаты в цифровой среде.</p>
      <p className="page-about__text mb-20">3.4. <b>Лучшая PR-кампания</b>  — вручается за PR-кампании, направленные на создание сильного имиджа бренда, управление репутацией и успешное взаимодействие с целевой аудиторией. Оценка включает стратегию и креативность в управлении общественными отношениями.</p>
      <p className="page-about__text mb-20">3.5. <b>Лучшее использование данных в маркетинге</b> — номинируются проекты, использующие аналитические данные для оптимизации маркетинговых решений и стратегий. Жюри оценивает внедрение data-driven подходов, их точность и полученные результаты.</p>
      <hr />

      <h2 className="section__title marg-40">
    <svg className="section__title-icon svg-icon" aria-hidden="true">
        <use xlinkHref={`${Wda_sprite}#title-pixel-4`}></use>
      </svg>
      4. Жюри и критерии оценки:</h2>
      <p className="page-about__text mb-20">4.1. Состав жюри формируется ежегодно и включает ведущих специалистов в области маркетинга и рекламы с международным признанием, что обеспечивает высокие стандарты оценки и признание международных трендов.</p>
      <p className="page-about__text mb-20">4.2. Каждый член жюри отбирается по следующим критериям:</p>
      <div className='page-about__text'>
        <ul className='mg-top'>
          <li className='page-about__list-item'>Опыт работы: минимум 5 лет практического опыта в маркетинговой сфере, что гарантирует глубокое понимание процессов и ключевых задач индустрии.</li>
            <li className='page-about__list-item'>Репутация и достижения: кандидат должен быть признанным экспертом, имеющим подтверждённые результаты и награды в своей профессиональной деятельности.</li>
            <li className='page-about__list-item'>Международный опыт: участие в международных проектах подчеркивает способность объективно оценивать проекты с глобальной точки зрения.</li>
            <li className='page-about__list-item'>Специализация: разнообразие жюри обеспечивает всестороннюю оценку проектов, включая цифровой маркетинг, PR, аналитический маркетинг и стратегическое планирование.</li>
            <li className='page-about__list-item'>Независимость и объективность: каждый член жюри обязан соблюдать принципы независимости и конфиденциальности при оценке.</li>
            <li className='page-about__list-item mb-20'>Активная профессиональная деятельность: члены жюри должны быть вовлечены в актуальные процессы маркетинга на момент проведения конкурса.</li>
        </ul>
      </div>
      <p className="page-about__text mb-20">4.3. Жюри оценивает работы участников на основе следующих критериев:</p>
      <div className='page-about__text'>
        <ul className='mg-top'>
          <li className='page-about__list-item'>Креативность и инновационность — насколько проект предлагает новые решения и подходы в своей категории.</li>
          <li className='page-about__list-item'>Эффективность и результаты — включая ROI, охват аудитории и влияние на имидж бренда.</li>
          <li className='page-about__list-item'>Реализация стратегии — насколько эффективно проект реализует свою стратегию на практике.</li>
          <li className='page-about__list-item mb-20'>Использование аналитических данных — для номинаций, связанных с аналитическими подходами, оценивается глубина анализа и использование полученных данных.</li>

        </ul>
      </div>
      <p className="page-about__text mb-20">4.4. Все члены жюри обязаны соблюдать конфиденциальность и независимость в процессе оценки.</p>
      <p className="page-about__text mb-20">4.5. Итоги голосования фиксируются в протоколе, который подписывается всеми членами жюри.</p>
      <p className="page-about__text mb-20">4.6. Решения жюри окончательные и не подлежат пересмотру.</p>
      <hr />

      <h2 className="section__title marg-40">
    <svg className="section__title-icon svg-icon" aria-hidden="true">
        <use xlinkHref={`${Wda_sprite}#title-pixel-5`}></use>
      </svg>
      5. Церемония награждения:</h2>
      <p className="page-about__text mb-20">5.1. Торжественная церемония награждения победителей проводится 1 октября, с участием представителей организаторов, жюри, участников и ключевых представителей индустрии.</p>
      <p className="page-about__text mb-20">5.2. Победители получают дипломы, памятные награды и имеют возможность представить свои проекты перед широкой аудиторией.</p>
      <p className="page-about__text mb-20">5.3. На церемонии присутствуют представители СМИ, а также идет освещение мероприятия в социальных сетях и на других платформах.</p>
      <p className="page-about__text mb-20">5.4. Участники и партнеры мероприятия получают медийное освещение через публикации в СМИ и социальных сетях.</p>
      <hr />
  
      <h2 className="section__title marg-40">
    <svg className="section__title-icon svg-icon" aria-hidden="true">
        <use xlinkHref={`${Wda_sprite}#title-pixel-6`}></use>
      </svg>
      6. Права и обязанности участников:</h2>
      <p className="page-about__text mb-20">6.1. Участники обязуются предоставлять достоверную информацию, соблюдать условия участия и поддерживать профессиональную этику на протяжении всего конкурса.</p>
      <p className="page-about__text mb-20">6.2. Организаторы оставляют за собой право использовать материалы участников для публикаций и продвижения конкурса. </p>
      <p className="page-about__text mb-20">6.3. Участники, нарушившие правила конкурса, могут быть дисквалифицированы.</p>
      <p className="page-about__text mb-20">6.4. Участники имеют право на получение обратной связи от жюри по результатам конкурса, что поможет им улучшить свои профессиональные навыки и проекты в будущем.</p>
      <hr />

      <h2 className="section__title marg-40">
    <svg className="section__title-icon svg-icon" aria-hidden="true">
        <use xlinkHref={`${Wda_sprite}#title-pixel-1`}></use>
      </svg>
      7. Партнеры и спонсоры:</h2>
      <p className="page-about__text mb-20">7.1. Конкурс поддерживается ведущими компаниями и организациями, работающими в сфере маркетинга и рекламы, что усиливает его престиж и значимость.</p>
      <p className="page-about__text mb-20">7.2. Партнеры конкурса получают возможность продвижения своих брендов в рамках мероприятия, а также участие в церемонии награждения и деловой программе.</p>
      <p className="page-about__text mb-20">7.3. Спонсоры могут предоставить специальные призы для победителей в номинациях, а также организовать собственные специальные награды.</p>
      <hr />

      <h2 className="section__title marg-40">
    <svg className="section__title-icon svg-icon" aria-hidden="true">
        <use xlinkHref={`${Wda_sprite}#title-pixel-2`}></use>
      </svg>
      8. Контактная информация:</h2>
      <p className="page-about__text mb-20">8.1. Для получения дополнительной информации участники могут связаться с организаторами:</p>
      <div className='page-about__text'>
        <ul className='mg-top'>
          <li className='page-about__list-item'>Российской Ассоциацией Маркетинговых Услуг (РАМУ)          </li>
          <li className='page-about__list-item'>Телефон: +7 (495) 662-39-88</li>
          <li className='page-about__list-item'>
  Сайт РАМУ: <a href="mailto:info@ramu.ru" className='disp_contents color-yellow font-pixel'>http://www.ramu.ru/</a>
</li>
          <li className='page-about__list-item mb-20'>Адрес офиса: г. Москва, ул. Полковая, д.3, стр.3, этаж 4</li>

        </ul>
      </div>
      <p className="page-about__text mb-20">8.2. На официальном сайте конкурса доступна форма обратной связи для отправки запросов и предложений.</p>

      <hr />

  </section>
  
  );
};

export default Regulations;
